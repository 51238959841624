<template>
  <div class="change-secret-phone">
    <van-nav-bar
      title="修改密保手机"
      left-arrow
      safe-area-inset-top
      placeholder
      @click-left="onClickLeft"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <van-cell-group>
      <van-form @submit="onSubmit" ref="editMoblie">
        <div class="change-phone-box">
          <van-field
            v-model="mobile"
            name="手机号"
            label="手机号"
            placeholder="请输入正确的手机号"
            :rules="[{ required: true, message: '手机号不能为空' }, { validator, message: '请输入正确的手机号' }]"
          />
        </div>
        <van-row>
          <van-col span="16">
            <div class="change-phone-box">
              <van-field
                v-model="captcha"
                name="验证码"
                label="验证码"
                placeholder="请输入验证码"
                :rules="[{ required: true, message: '请输入验证码' }]"
              />
            </div>
          </van-col>
          <van-col span="8">
            <van-button
              type="default"
              style="width: 90%"
              native-type="button"
              :disabled="state.smsSendBtn"
              @click="getCaptcha">
              {{ !state.smsSendBtn && '获取验证码' || (state.time + ' s') }}
            </van-button>
          </van-col>
        </van-row>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" :loading="state.loginBtn">保存</van-button>
        </div>
      </van-form>

    </van-cell-group>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ChangeSecretPhone',
  components: {},
  data () {
    return {
      mobile: '',
      captcha: '',
      state: {
        time: 60,
        loginBtn: false,
        smsSendBtn: false
      }
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight
    })
  },
  methods: {
    validator (val) {
      return /^1[34578]\d{9}$/.test(val)
    },
    getCaptcha(e) {
      const {
        state
      } = this
      const vali = this.$refs.editMoblie.validate('手机号')
      vali.then(() => {
        state.smsSendBtn = true

        const interval = window.setInterval(() => {
          if (state.time-- <= 0) {
            state.time = 60
            state.smsSendBtn = false
            window.clearInterval(interval)
          }
        }, 1000)
        this.$toast.loading({
          message: '验证码发送中...',
          forbidClick: true
        })
        const params = { mobile: this.mobile }
        console.log('params', params)
        this.$api.security.sendCaptcha(params).then(res => {
          this.$notify({ type: 'success', message: res.message })
        }).catch(err => {
          state.time = 60
          state.smsSendBtn = false
          console.log('err', err)
        })
      })
    },
    onSubmit() {
      console.log('submit')
      this.state.loginBtn = true
      const params = {
        mobile: this.mobile,
        captcha: this.captcha
      }
      this.$api.security.bindMobile(params).then(res => {
        this.$toast.success('修改手机成功！')
        this.state.loginBtn = false
        this.$router.push({ name: 'SecuritySetting' })
      }).catch(error => {
        this.state.loginBtn = false
        let errMsg = '修改手机失败！'
        if (error.response) {
          errMsg = error.response.data.message
        }
        this.$toast.fail(errMsg)
      })
    },
    onClickLeft () {
      // this.$router.push({ name: 'SecuritySetting' })
      window.history.go(-1)
    }
  },
  mounted () {
  }
}
</script>
<style lang="less">
.change-secret-phone {
  background-color: #f8f8f8;
  height: 100vh;
  background-image: url("~@/assets/background.png");
  background-size: 100% 40%;
  background-repeat: no-repeat;
  .van-nav-bar {
    background-color: transparent;
    &::after {
      border-bottom-width: 0
    }
    .van-nav-bar__content {
      height: 40px;
      .van-nav-bar__title {
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        font-family: PingFang SC;
      }
      .van-nav-bar__left {
        .van-icon-arrow-left {
          color: #000000;
          font-weight: bold;
          color: #ffffff;
          &::before {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .van-cell-group {
    background-color: transparent;
    overflow: auto;
    padding-top: 15px;
    .change-phone-box {
      margin: 0 15px 15px 15px;
      background-color: #ffffff;
      border-radius: 5px;
      .van-cell {
        border-radius: 5px;
        .van-cell__title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          .van-cell__label {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #878787;
            line-height: 28px;
          }
        }
      }
    }
    &::after {
      border-top-width: 0
    }
  }
}
</style>
